<template>
    <div>
        <h2>{{ $store.getters['app/GET_APP_MODE'] == 'create' ? "เพิ่ม" : "แก้ไข"}}เงื่อนไขการตอบคอมเม้น</h2>
        <v-form v-model="isFormValid">
          <v-card class="mt-4">
              <div class="pa-8">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="formData.rule"
                      outlined
                      label="เงื่อนไข"                               
                      :rules="[validators.required, validators.maxLengthValidator(formData.name, 50)]"
                      hide-details="auto"          
                      dense
                      prefix="/"
                      suffix="/"
                    ></v-text-field>
                  </v-col>
                </v-row>      
                <v-row>
                  <v-col cols="12" md="6">
                    <v-textarea
                      v-model="formData.response_message"
                      outlined
                      label="ข้อความตอบกลับ"                               
                      :rules="[validators.required, validators.maxLengthValidator(formData.name, 66534)]"
                      hide-details="auto"          
                      dense  
                    ></v-textarea>
                  </v-col>
                </v-row> 
              </div>

              <v-card-actions>              
                <v-btn
                  color="primary"               
                  large          
                  :disabled="!isFormValid"
                  :loading="sendingData"
                  @click="submitForm"
                  v-if="$store.getters['app/GET_APP_MODE'] != 'read'"
                >
                  {{ $store.getters['app/GET_APP_MODE'] == 'create' ? "สร้าง" : "แก้ไข"}}
                </v-btn>

                <v-btn
                  color="secondary"               
                  outlined                 
                  large
                  :disabled="sendingData"
                  @click="$router.go(-1)"
                >
                  กลับ
                </v-btn>                
              </v-card-actions>
          </v-card>
        </v-form>
    </div>
</template>
<script>
import { asyncGet, asyncPostAsJson } from '@/helpers/asyncAxios'
import { maxLengthValidator, required } from '@core/utils/validation'
export default {
  data() {
    return {
      isFormValid: false,
      sendingData: false,
      formData: {
        rule: null,
        response_message: null,
      },
      validators: {
        required,
        maxLengthValidator,
      },
    }
  },
  async created() {
    const responserule_id = this.$route.params.responserule_id

    if (responserule_id == 'create') {
      this.$store.commit('app/SET_APP_MODE', 'create')
      return
    } else if (this.$store.getters['auth/GET_ABILITY'].can('update', 'Setting'))
      this.$store.commit('app/SET_APP_MODE', 'edit')
    else this.$store.commit('app/SET_APP_MODE', 'read')

    this.$store.commit('app/SET_GLOBAL_LOADING', true)
    try {
      const { response_message, rule } = await asyncGet('/setting/responserule/' + responserule_id)

      this.formData = {
        rule: rule,
        response_message: response_message,
      }
    } catch (error) {
      this.$root.showCommonDialog('มีปัญหา', error)
    }
    this.$store.commit('app/SET_GLOBAL_LOADING', false)
  },
  methods: {
    async submitForm() {
      this.sendingData = true
      try {
        const responserule_id = this.$route.params.responserule_id

        if (responserule_id == 'create') await asyncPostAsJson('/setting/responserule', this.formData)
        else {
          await asyncPostAsJson('/setting/responserule/' + responserule_id, { ...this.formData, _method: 'PUT' })
        }

        this.$router.push({ name: 'responserule' })
      } catch (error) {
        console.log(error)
        this.$root.showCommonDialog('มีปัญหา', error)
      }

      this.sendingData = false
    },
  },
}
</script>